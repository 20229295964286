import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../pages/NotFound";

const Home = lazy(() => import("../pages/Home"));
const About = lazy(() => import("../pages/About"));
const Services = lazy(() => import("../pages/Services"));
const Contact = lazy(() => import("../pages/Contact"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const CookiePolicy = lazy(() => import("../pages/CookiePolicy"));
const TermsConditions = lazy(() => import("../pages/TermsConditions"));

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/lo-studio" element={<About />} />
    <Route path="/servizi" element={<Services />} />
    <Route path="/contatti" element={<Contact />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/cookie-policy" element={<CookiePolicy />} />
    <Route path="/termini-condizioni" element={<TermsConditions />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
