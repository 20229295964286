// src/components/layout/Footer.js
import React from "react";
import { Layout, Row, Col, Typography, Space, Divider } from "antd";
import { Link } from "react-router-dom";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

const { Footer } = Layout;
const { Title, Text, Paragraph } = Typography;

const links = [
  { title: "Home", url: "/" },
  { title: "Lo Studio", url: "/lo-studio" },
  { title: "Servizi", url: "/servizi" },
  { title: "Contatti", url: "/contatti" },
];

const legalLinks = [
  { title: "Privacy Policy", url: "/privacy-policy" },
  { title: "Cookie Policy", url: "/cookie-policy" },
  { title: "Termini e Condizioni", url: "/termini-condizioni" },
];

const AppFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* Onda decorativa in alto */}
      <div style={{ position: "relative", width: "100%", height: "80px" }}>
        <svg
          style={{ position: "absolute", top: 0, left: 0 }}
          viewBox="0 0 1440 80"
          preserveAspectRatio="none"
          width="100%"
          height="80"
        >
          <path
            fill="#02adc6"
            d="M0,60 C480,0 960,90 1440,30 L1440,80 L0,80 Z"
          />
        </svg>
      </div>

      <Footer
        style={{
          backgroundColor: "#02adc6",
          color: "#fff",
          padding: "40px 20px",
          fontSize: "16px",
          lineHeight: "1.6",
          marginTop: "-2px", // per sovrapporre leggermente l'onda
          position: "relative",
          zIndex: 1,
        }}
      >
        <div style={{ maxWidth: "960px", margin: "0 auto" }}>
          <Row gutter={[32, 32]} justify="center">
            {/* Colonna informazioni */}
            <Col xs={24} md={12}>
              <Title level={4} style={{ color: "#fff", marginBottom: "16px" }}>
                Studio Benvenuti
              </Title>
              <Paragraph style={{ marginBottom: "1rem" }}>
                Da oltre 25 anni offriamo servizi di consulenza specializzati
                per privati, aziende e studi legali.
              </Paragraph>

              <Space direction="vertical">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EnvironmentOutlined style={{ marginRight: 8 }} />
                  <Text>Via Dante Alighieri, 63, 09128 Cagliari CA</Text>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <PhoneOutlined style={{ marginRight: 8 }} />
                  <Text>
                    070 496647 / 070 7325277
                    {/* <- Aggiunto il secondo numero */}
                  </Text>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <MailOutlined style={{ marginRight: 8 }} />
                  <Text>info@studiomedicobenvenuti.it</Text>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ClockCircleOutlined style={{ marginRight: 8 }} />
                  <Text>Lun–Ven: 9:00-12:30 / 16:00-19:00</Text>
                </div>
              </Space>
            </Col>

            {/* Colonna Link */}
            <Col xs={24} sm={12} md={8}>
              <Title level={4} style={{ color: "#fff" }}>
                Link Rapidi
              </Title>
              <ul style={{ paddingLeft: 0, listStyle: "none" }}>
                {links.map((item, i) => (
                  <li key={i} style={{ marginBottom: 8 }}>
                    <Link to={item.url} style={{ color: "#fff" }}>
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          <Divider
            style={{ borderColor: "rgba(255,255,255,0.2)", margin: "32px 0" }}
          />

          <Row justify="space-between" align="middle">
            <Col xs={24} md={12}>
              <Text style={{ color: "#fff" }}>
                © {currentYear} Studio Benvenuti. Tutti i diritti riservati.
              </Text>
            </Col>
            <Col xs={24} md={12} style={{ textAlign: "mdright" }}>
              <Space size="middle">
                {legalLinks.map((item, i) => (
                  <Link key={i} to={item.url} style={{ color: "#fff" }}>
                    {item.title}
                  </Link>
                ))}
              </Space>
            </Col>
          </Row>
        </div>
      </Footer>
    </>
  );
};

export default AppFooter;
