import React, { useState, useEffect } from "react";
import { UpOutlined } from "@ant-design/icons";

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    const onScroll = () => setShowButton(window.scrollY > 100);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  if (!showButton) return null;
  return (
    <div
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      style={{
        position: "fixed",
        bottom: "50px",
        right: "50px",
        cursor: "pointer",
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "#fff",
        padding: "10px",
        borderRadius: "50%",
        fontSize: "20px",
        zIndex: 1000,
      }}
    >
      <UpOutlined />
    </div>
  );
};

export default ScrollToTopButton;
