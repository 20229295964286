import React from "react";
import { Layout, Button, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;

const menuItems = [
  { key: "/", label: "Home" },
  { key: "/lo-studio", label: "Lo Studio" },
  { key: "/servizi", label: "Servizi" },
  { key: "/contatti", label: "Contatti" },
];

const itemsForDropdown = menuItems.map((item) => ({
  key: item.key,
  label: <Link to={item.key}>{item.label}</Link>,
}));

const AppHeader = () => {
  return (
    <Header
      style={{
        backgroundColor: "#02adc6",
        display: "flex",
        alignItems: "center",
        padding: "0 24px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src="/favicon-96x96.png"
          alt="Studio Benvenuti"
          style={{ height: "40px", marginRight: "8px" }}
        />
        <Link
          to="/"
          style={{
            color: "#fff",
            fontSize: "1.3rem",
            fontWeight: 600,
            textDecoration: "none",
          }}
        >
          Studio Benvenuti
        </Link>
      </div>

      {/* Bottone menu più grande e con icona differente */}
      <div style={{ marginLeft: "auto" }}>
        <Dropdown
          menu={{ items: itemsForDropdown }}
          placement="bottomRight"
          trigger={["click"]}
        >
          <Button
            size="large" // Più grande
            icon={<MenuOutlined style={{ fontSize: 18 }} />}
            style={{
              backgroundColor: "#fff",
              color: "#02adc6",
              border: "none",
              fontWeight: 600,
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* Testo dopo l'icona */}
            <span style={{ marginLeft: 8 }}>Menu</span>
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;
