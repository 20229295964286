import React from "react";
import { Layout } from "antd";
import AppHeader from "./Header";
import AppFooter from "./Footer";
import ScrollToTopButton from "../../utils/scrollToTopButton";

const { Content } = Layout;
const AppLayout = ({ children }) => {
  return (
    <Layout className="layout" style={{ background: "#fff" }}>
      <AppHeader />
      <Content style={{ padding: 0, margin: 0 }}>{children}</Content>
      <AppFooter />
      <ScrollToTopButton />
    </Layout>
  );
};

export default AppLayout;
