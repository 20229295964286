import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Spin, ConfigProvider } from "antd";
import { HelmetProvider } from "react-helmet-async";
import AppRoutes from "./routes/AppRoutes";
import ScrollToTopOnRouteChange from "./utils/scrollToTopOnRouteChange";
import "./assets/styles/global.less";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#2b5e90",
          fontFamily:
            "'Roboto', 'Segoe UI', -apple-system, BlinkMacSystemFont, sans-serif",
        },
      }}
    >
      <HelmetProvider>
        <Router>
          <ScrollToTopOnRouteChange />
          <Suspense
            fallback={
              <div className="loading-container">
                <Spin size="large" />
              </div>
            }
          >
            <AppRoutes />
          </Suspense>
        </Router>
      </HelmetProvider>
    </ConfigProvider>
  );
}

export default App;
