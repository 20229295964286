import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Result, Button, Typography } from "antd";
import AppLayout from "../components/layout/AppLayout";

const { Paragraph, Text } = Typography;

const NotFound = () => {
  return (
    <AppLayout>
      <Helmet>
        <title>Pagina Non Trovata - Studio Benvenuti</title>
        <meta
          name="description"
          content="La pagina che stai cercando non esiste o è stata spostata."
        />
      </Helmet>

      <Result
        status="404"
        title="404"
        subTitle="Ci dispiace, la pagina che stai cercando non è stata trovata."
        extra={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Paragraph>
              <Text>
                La pagina potrebbe essere stata spostata, rinominata o rimossa,
                oppure potresti aver digitato incorrettamente l'URL.
              </Text>
            </Paragraph>
            <div style={{ marginTop: 20 }}>
              <Button type="primary" key="home">
                <Link to="/">Torna alla home</Link>
              </Button>
              <Button style={{ marginLeft: 16 }} key="contact">
                <Link to="/contatti">Contattaci</Link>
              </Button>
            </div>
          </div>
        }
      />
    </AppLayout>
  );
};

export default NotFound;
